import {light} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar, Breadcrumb, Card, Layout, Menu, MenuProps} from 'antd';
import React, {FC, PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate, useParams} from 'react-router-dom';

import './DefaultLayout.scss';
import {useAuth} from '../../hooks/useAuth';
import {useLang} from '../../hooks/useLang';
import {useStore} from '../../hooks/useStore';
import {Locale} from '../../i18n/locale';
import {AppRoutes} from '../../utils/route.helper';
import {AvatarBlock} from '../AvatarBlock';
import {FullScreenLoader} from '../FullScreenLoader';
import {LogoImage} from '../LogoImage';
import {PageTitle} from '../PageTitle';
import {Footer} from './Footer';

interface DefaultLayoutProps {
  pageTitle?: string;
  loading?: boolean;
  hideBreadcrumb?: boolean;
}

export const DefaultLayout: FC<PropsWithChildren<DefaultLayoutProps>> = ({children, pageTitle, loading = false, hideBreadcrumb}) => {
  const {user} = useAuth();
  const lang = useLang();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {orderId, userId} = useParams();
  const {users} = useStore();

  const layoutMenuHandler: MenuProps['onClick'] = ({key}) => {
    switch (key) {
      case 'customers':
        navigate(AppRoutes.customers.index({lang}));
        break;

      case 'orders':
        navigate(AppRoutes.orders.list({lang, customerId: user?.customerId}));
        break;

      case 'users':
        navigate(AppRoutes.users.index({lang}));
        break;
    }
  };

  const menuItems: {key: React.Key; icon: React.ReactNode}[] = user?.isAdmin
    ? [
        {key: 'customers', icon: <FontAwesomeIcon icon={light('grid-2')} />},
        {key: 'users', icon: <FontAwesomeIcon icon={light('user')} />},
      ]
    : user
    ? [{key: 'orders', icon: <FontAwesomeIcon icon={light('grid-2')} />}]
    : [];

  const getBreadcrumbItems = (): React.ReactNode => {
    const breadcrumbPathMap = [
      {
        key: 'user-list',
        regex: /\/users$/m,
        label: t('users.list.title'),
        hidden: !user?.isAdmin,
      },
      {
        key: 'user-detail',
        regex: /\/users\/(\d+|me)$/m,
        label:
          userId === 'me' ? t('users.detail.my_profile') : users?.find(({id}) => id === userId)?.fullName || t('users.detail.user_detail'),
      },
      {
        key: 'user-change-password',
        regex: /\/users\/(\d+|me)\/change-password$/m,
        label: t('actions.change_password'),
      },
      {
        key: 'customer-list',
        regex: /customers$/m,
        label: t('customers.list.title'),
        hidden: !user?.isAdmin,
      },
      {
        key: 'customer-detail',
        regex: /customers\/\d+$/m,
        label: '',
        hidden: true,
      },
      {
        key: 'order-list',
        regex: /customers\/\d+\/orders$/m,
        label: t('orders.list.title'),
      },
      {
        key: 'order-detail',
        regex: /customers\/\d+\/orders\/\d+$/m,
        label: t('orders.detail.title', {no: orderId}),
      },
    ];

    const pathSnippets = location.pathname.split('/').filter((i) => i);

    const extraBreadcrumbItems: (React.ReactNode | null)[] = pathSnippets
      .filter((parts) => !Object.values<string>(Locale).includes(parts.toLowerCase()))
      .map((parts, i, paths) => {
        const url = `/${[lang, ...paths.slice(0, i + 1)].join('/')}`;

        const {label = '', hidden = false} = breadcrumbPathMap.find(({regex}) => url.match(regex)) || {};

        return (
          !hidden && (
            <React.Fragment key={url}>
              <Breadcrumb.Separator>»</Breadcrumb.Separator>
              <Breadcrumb.Item className={hidden ? 'hidden' : ''}>
                <Link to={url}>{label}</Link>
              </Breadcrumb.Item>
            </React.Fragment>
          )
        );
      })
      .filter((v) => v);

    return [
      <Breadcrumb.Item key={'home'}>
        <Link to={'/'}>
          <FontAwesomeIcon icon={light('house')} />
        </Link>
      </Breadcrumb.Item>,
      ...extraBreadcrumbItems,
    ];
  };

  return (
    <Layout className={'min-h-screen'}>
      <Layout.Header className={'border-b border-bright-gray flex px-6 items-center justify-between leading-none'}>
        <LogoImage rootClassName={'h-full flex items-center justify-between'} className={'h-full'} linkClassName={'h-full max-h-[50px]'} />
        <Menu
          className={'ml-auto mr-1.5 sm:mr-5 border-none'}
          mode={'horizontal'}
          onClick={layoutMenuHandler}
          items={menuItems.map(({icon, key}) => ({key, icon: <Avatar className={'flex center-v-h'} icon={icon} size={26} />}))}
        />
        <AvatarBlock />
      </Layout.Header>

      <Layout.Content className={'flex flex-col p-6 lg:px-12 transition-spacing duration-300 gap-5'}>
        {!hideBreadcrumb && <Breadcrumb separator={''}>{getBreadcrumbItems()}</Breadcrumb>}

        <Card className={'max-w-full flex-auto border-bright-gray'}>
          {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
          <FullScreenLoader active={loading}>{children}</FullScreenLoader>
        </Card>
      </Layout.Content>

      <Footer />
    </Layout>
  );
};
