import {deleteObject, getDownloadURL, getStorage, list, ref, uploadBytes} from '@firebase/storage';
import {regular} from '@fortawesome/fontawesome-svg-core/import.macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form, Input, Modal, Select, Typography, Upload, UploadProps} from 'antd';
import {UploadFile} from 'antd/lib/upload/interface';
import {Moment} from 'moment';
import {Rule} from 'rc-field-form/lib/interface';
import React, {FC, PropsWithChildren, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FormItem} from 'src/components/form/FormItem';
import {v4 as uuidv4} from 'uuid';

import {FileUploadError} from '@wc-vermietung/library/errors/file-upload-error';
import {Attachment, Attachments} from '@wc-vermietung/library/interfaces/attachment';
import {NewOrderPartialDocument} from '@wc-vermietung/library/interfaces/new-order';
import {OrderServiceFrequency} from '@wc-vermietung/library/interfaces/order';
import {NewOrderModel} from '@wc-vermietung/library/models/new-order';
import {NewOrderRepository} from '@wc-vermietung/library/repositories/client/new-order';

import {AmountOfCabinsSelectOptions} from '../../../../../components/AmountOfCabinsSelectOptions';
import {Button} from '../../../../../components/Button';
import {AddressFormItem} from '../../../../../components/form/AddressFormItem';
import {useAuth} from '../../../../../hooks/useAuth';
import {useStore} from '../../../../../hooks/useStore';
import {i18n} from '../../../../../i18n/i18n';
import {openNotification} from '../../../../../utils/notification.helper';
import {REGEX} from '../../../../../utils/regex.helper';
import {DesiredDeliveryDateMode, DesiredDeliveryDateSelector} from './_components/DesiredDeliveryDateSelector';

interface NewOrderForm extends Omit<NewOrderPartialDocument<Date>, 'desiredDeliveryDate' | 'customerId' | 'filledOutById' | 'attachments'> {
  desiredDeliveryDate: Moment;
  attachments: Pick<UploadProps, 'fileList'>;
}

interface NewOrderModalProps {
  customerId: string | undefined;
}

const uploadSizeRule = ({maxSizeMb = 10}: {maxSizeMb?: number} = {}): Rule => {
  const t = i18n.t;
  const maxSizeBytes = maxSizeMb * 1024 * 1024;

  const validator = (_: unknown, {fileList}: {fileList: File[]} = {fileList: []}): Promise<void> => {
    const totalSizeBytes = fileList.reduce((acc, currFile) => acc + (currFile.size || 0), 0);
    if (totalSizeBytes <= maxSizeBytes) {
      return Promise.resolve();
    }

    return Promise.reject(new Error(t<string>('message.file_size_limit_exceeded', {maxSizeMb})));
  };

  return {validator};
};

export const NewOrderModalButton: FC<PropsWithChildren<NewOrderModalProps>> = ({customerId}) => {
  const {t} = useTranslation();
  const {isCustomersLoading, customers} = useStore();
  const {user} = useAuth();

  const [visible, setVisible] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const [form] = Form.useForm<NewOrderForm>();

  const customer = customerId && customers.find(({id}) => id === customerId);

  const deleteUploadedFiles = async (newOrderId: string) => {
    try {
      const storage = getStorage();
      const pathToDelete = `new-orders/${newOrderId}`;

      const folderRef = ref(storage, pathToDelete);

      const {items} = await list(folderRef);

      for (const item of items) {
        await deleteObject(item);
      }
    } catch (error) {
      console.error('Error on deleting uploaded files', error);
      setInProgress(false);
    }
  };

  const uploadSingleFile = async (file: UploadFile, newOrderId: string): Promise<Attachment> => {
    const bucket = getStorage();

    if (!file.type) {
      return Promise.reject('extension empty');
    }

    const storagePath = `/new-orders/${newOrderId}/${file.name}`;
    const storageRef = ref(bucket, storagePath);

    await uploadBytes(storageRef, file.originFileObj as Blob);

    const downloadUrl = await getDownloadURL(storageRef);

    return {
      downloadUrl,
      path: storagePath,
    };
  };

  const uploadFiles = async (newOrderId: string, attachments: NewOrderForm['attachments']): Promise<Attachments> => {
    const tmpAttachments: Attachments = [];
    for (const file of attachments?.fileList || []) {
      if (!file?.originFileObj?.name) {
        continue;
      }
      try {
        const uploadFile = await uploadSingleFile(file, newOrderId);
        tmpAttachments.push(uploadFile);
      } catch (error) {
        throw new FileUploadError([file.originFileObj.name]);
      }
    }
    return tmpAttachments;
  };

  if (!(user && customer)) {
    return null;
  }

  const createNewOrder = async ({
    partialDocument,
    attachments,
  }: {
    partialDocument: NewOrderPartialDocument<Date>;
    attachments: NewOrderForm['attachments'];
  }) => {
    let errorFiles = '';

    const uuid = uuidv4();

    let uploadedFiles: Attachments = [];

    try {
      uploadedFiles = await uploadFiles(uuid, attachments);
    } catch (error) {
      if (error instanceof FileUploadError) {
        errorFiles = error.files.join(', ');
      }
      await deleteUploadedFiles(uuid);
      openNotification({
        type: 'error',
        description: t('message.new_order_modal.error_creating_order', {fileNames: errorFiles}),
      });
      setInProgress(false);
      return;
    }

    await new NewOrderRepository().createWithId(
      uuid,
      new NewOrderModel({...partialDocument, createdAt: new Date(), attachments: uploadedFiles}),
    );

    setInProgress(false);
    openNotification({type: 'success', message: t('message.new_order_modal.successfully_ordered')});
  };

  const onClose = () => {
    setVisible(false);
  };

  const onSubmit = () => {
    void form.validateFields().then(({attachments, ...v}) => {
      createNewOrder({
        partialDocument: {
          ...v,
          desiredDeliveryDate: new Date(v.desiredDeliveryDate.toDate()),
          filledOutById: user.id,
          customerId,
          attachments: [],
        },
        attachments,
      }).catch((error) => console.error('there was an error while creating a new order. ', error));
      setVisible(false);
    });
  };

  return (
    <>
      <Button className={'text-xs'} loading={isCustomersLoading} hasIcon onClick={() => setVisible(true)}>
        <FontAwesomeIcon icon={regular('cart-shopping')} />
        {t('orders.table.header.action.new_order')}
      </Button>

      <Modal
        visible={visible}
        title={t('modal.new_order_modal.title')}
        okText={t('actions.order')}
        cancelText={t('actions.cancel')}
        onOk={onSubmit}
        onCancel={onClose}
        afterClose={() => {
          setInProgress(false);
          form.resetFields();
        }}
        confirmLoading={inProgress}
        destroyOnClose={true}
        width={'780px'}
      >
        <Form
          form={form}
          layout={'horizontal'}
          requiredMark={'optional'}
          labelAlign={'left'}
          className={'ant-form-fix-item-label-colon'}
          initialValues={{
            amountOfCabins: 1,
            constructionSiteNo: '',
            note: '',
            deliveryAddress: {name: ''},
            billingAddress: {name: customer.name, street: customer.address.street, zip: customer.address.zip, city: customer.address.city},
            sendInvoiceToAddress: {name: '', street: '', zip: '', city: ''},
            filledOutByName: user.fullName,
            customerName: customer.name,
            filledOutByEmail: user.email,
          }}
          labelCol={{span: 8}}
          wrapperCol={{flex: 1}}
        >
          <AddressFormItem formItemParentName={'deliveryAddress'} header={t('view.new_order_modal.form.delivery_address')} />
          <Typography.Paragraph className={'font-medium mb-0'}>{t('view.new_order_modal.form.details')}</Typography.Paragraph>
          <FormItem label={t('view.new_order_modal.form.desired_delivery_date')} name={'desiredDeliveryDate'} rules={[{required: true}]}>
            <DesiredDeliveryDateSelector mode={DesiredDeliveryDateMode.SOON_AS_POSSIBLE} />
          </FormItem>
          <FormItem label={t('view.new_order_modal.form.amount_of_cabins')} name={'amountOfCabins'} rules={[{required: true}]}>
            <AmountOfCabinsSelectOptions />
          </FormItem>
          <FormItem label={t('view.new_order_modal.form.service_frequency')} name={'serviceFrequency'} rules={[{required: true}]}>
            <Select placeholder={t('form.placeholder.service_frequency.select_one_option')}>
              {Object.values(OrderServiceFrequency).map((v) => (
                <Select.Option value={v} key={v}>
                  {t(`models.order.service_frequencies.${String(v)}`)}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem label={t('view.new_order_modal.form.contact_person')} name={'contactPersonName'} rules={[{required: true}]}>
            <Input />
          </FormItem>
          <FormItem label={t('person.mobile')} name={'contactPersonMobile'} rules={[{required: true}]}>
            <Input />
          </FormItem>
          <FormItem label={t('view.new_order_modal.form.construction_site_no')} name={'constructionSiteNo'}>
            <Input />
          </FormItem>
          <FormItem
            label={t('view.new_order_modal.form.estimated_rental_period')}
            name={'estimatedRentalPeriod'}
            rules={[{required: true}]}
          >
            <Input />
          </FormItem>

          <AddressFormItem formItemParentName={'billingAddress'} header={t('view.new_order_modal.form.billing_address')} />
          <AddressFormItem
            formItemParentName={'sendInvoiceToAddress'}
            header={t('view.new_order_modal.form.send_invoice_to_address')}
            fullyOptional
          />

          <Typography.Paragraph className={'font-medium mb-0'}>{t('view.new_order_modal.form.filled_out_by')}</Typography.Paragraph>
          <FormItem label={t('person.name')} name={'filledOutByName'} rules={[{required: true}]}>
            <Input disabled />
          </FormItem>
          <FormItem label={t('view.new_order_modal.form.customer_name')} name={'customerName'} rules={[{required: true}]}>
            <Input disabled />
          </FormItem>
          <FormItem
            label={t('person.email')}
            name={'filledOutByEmail'}
            rules={[{required: true}, {pattern: REGEX.email, message: t('form.errors.invalid_email')}]}
          >
            <Input disabled />
          </FormItem>
          <FormItem label={t('person.mobile')} name={'filledOutByMobile'} rules={[{required: true}]}>
            <Input />
          </FormItem>

          <Typography.Paragraph className={'font-medium mb-0 mt-6'}>{t('view.new_order_modal.form.other')}</Typography.Paragraph>
          <FormItem label={t('view.new_order_modal.form.note')} name={'note'}>
            <Input.TextArea showCount maxLength={255} />
          </FormItem>
          <FormItem label={t('view.new_order_modal.form.attachments')} name={'attachments'} rules={[uploadSizeRule()]}>
            <Upload
              accept={'image/*,.txt,application/pdf'}
              multiple={true}
              beforeUpload={() => false}
              listType={'picture'}
              onPreview={() => false}
            >
              <Button>{t('view.new_order_modal.form.attachments_button_name')}</Button>
            </Upload>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};
