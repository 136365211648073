import {Layout, Space, Typography} from 'antd';
import dayjs from 'dayjs';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {Config} from '../../config';
import {VERSION} from '../../version';

export const Footer: FC = () => {
  const {t} = useTranslation();

  return (
    <Layout.Footer className={'flex row justify-between text-tiny p-6 sm:px-10 flex-wrap gap-y-1.5'}>
      <Typography.Text type={'secondary'}>
        <Space split={'|'} size={4}>
          <Typography.Text type={'secondary'}>
            &copy;{dayjs().year()} {Config.company.name}
          </Typography.Text>
          <Typography.Text type={'secondary'}>v{VERSION}</Typography.Text>
        </Space>
      </Typography.Text>
      <Typography.Text type={'secondary'}>
        <Space split={'|'} size={4}>
          <Typography.Link href={'https://www.wcvermietung.ch/datenschutz'} target={'_blank'}>
            {t('footer.links.term_of_service')}
          </Typography.Link>
          <Typography.Link href={'https://www.wcvermietung.ch/impressum'} target={'_blank'}>
            {t('footer.links.imprint')}
          </Typography.Link>
        </Space>
      </Typography.Text>
    </Layout.Footer>
  );
};
